/*Sidebar*/
.account, .page-products{
  .sidebar-main{
    width: 24rem;
    background-color: $colorPrimary;
    padding: 2rem;
    color: $colorWhite;
    order: 1;
    a, strong{
      color: $colorWhite;
    }
  }
}
.sidebar-main {
  padding-top: 2rem;
  .block{
    margin-bottom: 2rem;
    &-title{
      @include fontDefault;
      font-size: 2.2rem;
      text-align: left;
      padding-bottom: 1rem;

      border-bottom: 2px solid $colorWhite;
      margin-bottom: 1rem;

      &:after {

      }
    }
    &-content{

    }

  }
  .category{
    &-list{
      &.level {
        &-3 {
          &.active {
            .category-item.level-3:not(.active) {
              opacity: 30%;
            }
          }
        }


      }
    }
    &-item{
      &.level {
        &-3 {
          border-bottom: 1px solid $colorLightGrey;
          min-height: 12.1rem;
          & > a {
            padding-top: 2.2rem;
            @include flexCenter;
            flex-direction: column;
            @include fontSmaller;
            font-weight: bold;
            line-height: 2.3rem;

            &:before {
              height: 4.5rem;
            }
          }

          &.active {
            a {
              font-weight: bold;
            }
          }
          &.active ul.category-list.level-4 {
            max-height: 30rem;
            @include defaultTrans;
            padding-top: 2.4rem;
            padding-bottom: 3.8rem;
          }
        }
        &-4{

        }
      }
    }
  }




}

