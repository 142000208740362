button{
  cursor: pointer;
}
.page-main, .footer-main, .header-main, .cookie, #notice-cookie-block, .modals-wrapper{
  .secondary {
    @include flex;
    align-items: center;
  }
  .action {
    cursor: pointer;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: 0.2rem;
    margin-right: 2rem;
    @include defaultTrans;
    @include fontMedium;
    border: none;
    line-height: 1;
    @include defaultFastTrans;
    &.primary, &.tocart.primary, &.allow  {
      color: $colorWhite;
      background-color: $colorBlack;
      &:hover {
        @include defaultTrans;
      }
      &.tocart.disabled {
        border-color: $colorSuccess;
        background-color: $colorSuccess;
      }

    }
    &.secondary, &.action-edit-address , &.action.edit-address-link, &.tocategory.secondary, &.totag.secondary, &.action-select-shipping-item, &.back, &.remind, &.tocart, &.reload, &.clear, &.update, &.action-hide-popup, &.action-show-popup, &.action-update, &.action-cancel, &.share {
      color: #4b5458;
      background-color: $colorWhite;
      border: 1px solid #4B5458;
      text-align: center;
      &:hover {
        @include defaultTrans;
      }
    }
    @media only screen and (max-width: #{$mediumBreakpoint}) {
      margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
      font-size: 1.4rem;
      letter-spacing: 0.21rem;
      line-height: 3.2rem;
    }
  }
}
#minicart-content-wrapper .secondary .action, a.action.print, .block.block-dashboard-orders .block-title.order a.action.view {
  @include defaultFastTrans;
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;;
  border-radius: 2.3rem;
  margin-right: 2rem;
  color: $colorBlack;
  background-color: transparent;
  border: 0.1rem solid black;
  @include defaultFastTrans;
  &:hover {
    border: 0.2rem solid black;
  }
}
.block.block-dashboard-orders .block-title.order a.action.view {
  position: absolute;
  right: 0;
  bottom: 1.5rem;
  margin-right: 0;
}
.emptyButton {
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  margin-left: 0.1rem;
  &:hover {
    margin-left: 0rem;
    border: none;
  }
}
.top-container .overlay .action{
  display: inline-block;
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: $colorWhite;
  padding: 1.4rem 2rem;
  border: 0.2rem solid $colorWhite;
  background-color: transparent;
  margin: 0.2rem;
  &:hover {
    margin: 0;
    background-color: $colorWhite;
  }
}
.page-main  .actions-toolbar {
  display: flex;

}
.page-main .action.delete, .page-main .action-delete, .page-header .action.delete {
  text-decoration: none;
  max-width: inherit;
  min-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  > span {
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;
  }
  &:before {
    height: 2.5rem;
    width: 2.5rem;
    content: '';
    display: block;
  }
}
.page-main{
  .action{
    &.edit, &-edit, &.change-password, &-change-password{
      text-decoration: none;
      max-width: inherit;
      min-height: inherit;
      margin: 0;
      padding: 0;
      border: none;
      font-size: 0;
      letter-spacing: 0;
      > span{
        height: 0.1rem;
        overflow: hidden;
        position: absolute;
        width: 0.1rem;
        display: none;
      }
      &:before {
        @include fontIcon;
        @include icon;
        font-size: 1.4rem;
        width: auto;
        height: auto;
        padding-left: 1rem;
      }

    }
  }
}
.page-main{
  .action{
    &.edit, &-edit{
      &:before {
        content: $iconEdit;
      }
    }
  }
}
.page-main{
  .action{
    &.change-password, &-change-password{
      &:before {
        content: $iconKey;
      }
    }
  }
}

.widget.magento-widget.category-overview .widget-content {
  margin: 0 auto;
  display: flex;
  padding-bottom: 12rem;
  padding-top: 10rem;
  justify-content: center;
  a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
input.action.submit.secondary:hover {
  margin: 0 0.1rem;
}

.product-info-category {
  ul li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }
    }
  }

}
ul.categories.list {
  li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }

    }
  }
}
